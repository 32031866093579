body
{
    padding : 0px;
    margin : 0px; 
}

.container
{
    display : flex;
    justify-content : center;
    align-items : center;
    height : 100vh;
    width : 100vw;
}

.container .signin
{
    display : block;
    justify-content : center;
    align-items : center;
    text-align : center;
    padding : 30px;
    box-shadow : 0px 0px 15px 5px rgb(125, 125, 125);
    font-family : system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.signin h1
{
    font-size : 64px;
}

.signin .border
{
    border : 1px solid black;
    width : 420px;
}

.signin input[type="text"]
{
    width : 400px;
    font-family : system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size : 24px;
    padding : 10px;
    border : none;
    outline : none;
}

.signin input[type="password"]
{
    width : 400px;
    font-family : system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size : 24px;
    padding : 10px;
    border : none;
    outline : none;
}

.signin input[type="submit"]
{
    padding : 15px;
    padding-left : 40px;
    padding-right : 40px;
    font-family :'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size : 48px;
    background-color : rgb(24, 173, 106);
    border : none;
    outline : none;
}

.signin input[type="submit"]
{
    cursor : pointer;
}