.container
{
    display : flex;
    justify-content : center;
    text-align : center;
}

.container .editor
{
    display : block;
    padding : 10px;
    box-shadow : 0px 0px 15px 5px rgb(95, 95, 95);
    height : auto;
    width : 35vw;
}

.editor h1
{
    font-size : 32px;
    font-family : system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.editor img
{
    height : auto;
    width : 90%;
}

.editor input[type="range"]
{
    width : 98%;
}

.options
{
    text-align : left;
}

.options h2
{
    font-size : 24px;
    font-family : system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color : rgb(75, 75, 75);
    margin-left : 10px;
}

#choose
{
    display : none;
}

.editor label
{
    padding : 10px;
    font-size : 24px;
    font-family : system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    background :rgb(8, 244, 169);
    border : none;
    border-radius : 10px;
    color : white;
}

.filter
{
    display : grid;
    grid-template-columns : 1fr 1fr 1fr;
    grid-template-rows : 1fr;
    gap : 10px;
}

.filter button
{
    padding : 10px;
    font-size : 24px;
    font-family : system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    border : 2px solid rgb(8, 244, 169);
    background : white;
    color : black;
    border-radius : 10px;
}

.options .save
{
    display : grid;
    grid-template-columns : 1fr 1fr 1fr;
}

.save button
{
    background : rgb(255, 153, 1);
    border : none;
    padding : 10px;
    font-size : 24px;
    font-family : system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    border-radius : 10px;
}